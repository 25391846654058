import React from 'react';
import AlertMessage from "../Components/Overlay/AlertMessage";

class Validations {

    static validateEmail(value){
        let message = "";
        let valid = true;
        let pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!pattern.test(value)) {
            message = "The email must be a valid email address.";
            this.showValidationErrorMessage(message);
            valid = false;
        }
        return valid;
    }

    static showValidationErrorMessage(message){
        AlertMessage.getInstance().show({show:true,message:message});
    }
}

export default Validations;