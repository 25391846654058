import React from 'react';

import './MessageItemContainer.scss'
import UserModel from '../../../../Model/UserModel';
import ConnectionType from '../../../../Connect/ConnectionType';

import MessageFromUser from './MessageFromUser';
import MessageToUser from './MessageToUser';


class MessageItemContainer extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);
    this.createContentKey = this.createContentKey.bind(this);
    this.renderLayout = this.renderLayout.bind(this);
    this.userIcon = UserModel.getInstance().getData().profileImage;
  }

  createContentKey(id,type){
    let key = "content_" + id + "_" + type;
    return key;
  }

  renderLayout(){
    if(this.props.data === undefined || this.props.data.length === 0){
      return [];
    }

    let fromUser = this.props.data[0].fromUser;

    if(fromUser){
      return <MessageFromUser theme={this.props.theme} userIcon={this.props.profileImage} data={this.props.data}/>
    }else{
      return <MessageToUser theme={this.props.theme} userIcon={this.userIcon} data={this.props.data}/>
    }
    
  }

  render(){
    return(
      <div className="MessageItemContainer">
        {this.renderLayout()}
      </div>
    )
  }

}

export default MessageItemContainer;