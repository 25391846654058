import React from 'react';
import RatingStar from './Extras/RatingStar'
import TaskControl from '../../../../Control/TaskControl';
import TaskModel from '../../../../Model/TaskModel';
import AlertMessage from '../../../Overlay/AlertMessage';

class MessageTypeRating extends React.PureComponent{
  
  constructor(props){
    super(props);

    this.state = {
      rating:0,
      rated:false,
      loaded:false,
    }

    this.commentField = React.createRef()
    this.getTitleText = this.getTitleText.bind(this)
    this.getRating = this.getRating.bind(this)
    this.onRatingUpdated = this.onRatingUpdated.bind(this)
    this.onSubmitRating = this.onSubmitRating.bind(this)
    this.renderInput = this.renderInput.bind(this)
  }

  componentDidMount(){
    this.getRating()
  }

  async getRating(){
    var response = await TaskControl.getInstance().getRating(
      this.props.data.unixTimeStamp,
      this.props.data.taskId,
    )

    if(response.result){
      if(response.data == null){
        this.setState({loaded:true, rated:false})
      }else {
        this.setState({loaded:true, rated:true, rating:response.data})
      }
    }else{
      AlertMessage.getInstance().show({
        show: true,
        title: 'Error',
        message:'Unable to load rating details at the moment'
      })
    }
  }

  onRatingUpdated(value){
    if(this.state.rated){
      return
    }

    this.setState({rating:value})
  }

  async onSubmitRating(){
    console.log("SUBMIT")
    if(this.state.rating === 0){
      AlertMessage.getInstance().show({
        show: true,
        title: 'Oops',
        message:'Unfortunately you cannot a zero rating'
      })
      return
    }

    var response = await TaskControl.getInstance().rateTask(
      this.commentField.value,
      this.props.data.unixTimeStamp,
      this.state.rating,
      this.props.data.taskId,
    )

    if(response.result){
      this.setState({rated:true})
      // TaskModel.getInstance().removeTaskFromOpenList(this.props.data.taskId)
      // TaskControl.getInstance().setViewTaskType("closed");
    }

  }

  renderStar(index, rating){
    return (<RatingStar value={index} rating={rating} onRatingUpdated={this.onRatingUpdated}/>)
  }

  renderInput(){
    if(this.state.rated){
      return <div className="RatingThanks">Thanks for Rating</div>
    }

    return([
      <textarea
        ref={ref => this.commentField = ref}
        placeholder="Comments..." className="RatingInput"/>,
      <button className="RatingButton" onClick={this.onSubmitRating}>Submit</button>
    ])
  }

  getTitleText(){
    if(this.state.rated){
      return "This request has been marked as complete."
    } else {
      return this.props.data.text
    }
  }

  renderCell(){
    return(
      <div className="RatingCell">
        <div className="RatingCellTitle">{this.getTitleText()}</div>
          <div className="RatingBar">
            {this.renderStar(1,this.state.rating)}
            {this.renderStar(2,this.state.rating)}
            {this.renderStar(3,this.state.rating)}
            {this.renderStar(4,this.state.rating)}
            {this.renderStar(5,this.state.rating)}
          </div>
          {this.renderInput()}
        </div>
    )
  }

  renderLoad() {
    return(
        <div className="AlertLoader"/>
    )
  }

  render() {
    if(this.state.loaded){
      return this.renderCell()
    }else {
      return this.renderLoad()
    }
  }

}

export default MessageTypeRating