import React from 'react';

class VendorCard extends React.PureComponent{

  constructor(props){
    super(props);
    this.lat = this.props.data.latitude;
    this.lon = this.props.data.longitude;
    this.mapView = "https://maps.googleapis.com/maps/api/staticmap?&key=AIzaSyDMRnEbWKQo4WCWlGVBGim0jyI33oxWEgg&size=340x220&maptype=roadmap&zoom=15&center="+this.lat+","+this.lon;
    //this.mapView = "https://via.placeholder.com/300X200?text=Map(300x200)";
  }

  render(){
    let { name, rating, address, phone, mobile, description } = this.props.data 

    return(
      <div className="VendorCell">
        <div className="VendorTitle">
          {name}
          <div>{rating}/5</div>
        </div>
        <img className="VendorMap" src={this.mapView} alt="Map"/>
        <div className="VendorDetails">
          <div className="VendorDetailsItem">{address}</div>
          <div className="VendorDetailsItem">tel:{phone}<br/>mobile: {mobile}</div>
          <div>{description}</div>
        </div>
      </div>
    )
  }
}

class MessageTypeVendor extends React.PureComponent{

  constructor(props){
    super(props);
    this.renderVendorCards = this.renderVendorCards.bind(this);
  }

  renderVendorCards(){
    var displayList = [];
    for(var dataItem of this.props.data.vendors){
      displayList.push(<VendorCard data={dataItem}/>);
    }
    return displayList
  }

  render(){
    return(
      <div className="VendorContainer">
        {this.renderVendorCards()}
      </div>
    )
  }

}

export default MessageTypeVendor;