import BaseModel from './BaseModel';
import ConnectionType from '../Connect/ConnectionType';
import Utils from '../Utils/Utils';

class TaskModel extends BaseModel{

  static getInstance(){
    if(TaskModel.instance === undefined){
      TaskModel.instance = new TaskModel();
    }
    return TaskModel.instance;
  }

  constructor(){
    super()

    this.data={
      openTasks:[], 
      closedTasks:[],
      currentTaskId:undefined, //TODO: set this up globally
    }
  }

  setViewTaskType(type){
    this.dispatch(ConnectionType.TYPE_TASK_TYPE_SELECTED,type);
  }

  populateOpenTaskData(data){
    this.data.openTasks = data
    this.dispatch(ConnectionType.TYPE_TASK_OPEN_DATA, this.data.openTasks);
  }

  populateClosedTaskData(data){
    this.data.closedTasks = data;
    this.dispatch(ConnectionType.TYPE_TASK_CLOSED_DATA, this.data.closedTasks);
  }

  removeTaskFromOpenList(taskId){
    let index = Utils.getIndexInArrayFromId(taskId, this.data.openTasks);
    if(index > -1){
      this.data.openTasks.splice(index,1);
    }
    this.dispatch(ConnectionType.TYPE_TASK_OPEN_DATA, this.data.openTasks);
  }

  removeTaskFromClosedList(taskId){
    let index = Utils.getIndexInArrayFromId(taskId, this.data.closedTasks);
    if(index > -1){
      this.data.closedTasks.splice(index,1);
    }
    this.dispatch(ConnectionType.TYPE_TASK_CLOSED_DATA, this.data.closedTasks);
  }
}

export default TaskModel;
