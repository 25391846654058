import axios from 'axios';
import { isTokenValid } from './AuthHelper';
import JudeError from '../Models/Error';

export class ApiHelper {

    constructor(environment, program, xApiKey, callbackTokenUpdated) {
        this.environment = environment;
        this.program = program;
        this.token = null;
        this.tokenUpdated = callbackTokenUpdated;
        this.baseURL = null;
        this.xApiKey = xApiKey;

        switch (environment) {
            case 'local':
                this.baseURL = 'https://heyjudebackend.tctdigital.xyz/';
                break;
            case 'staging':
                this.baseURL = 'https://staging.heyjudeapp.com/';
                break;
            case 'training':
                this.baseURL = 'https://training.heyjudeapp.com/';
                break;
            case 'live':
                this.baseURL = 'https://agent.heyjudeapp.com/';
                break;
            default:
                new Error('Invalid Environment');
        }

        this.axios = axios.create({
            "baseURL": this.baseURL.concat('api/v1/'),
            "headers": {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'x-api-key': this.xApiKey,
                Authorization: 'Bearer '.concat(this.token)
            }
        });
    }

    getBaseURL() {
        return this.baseURL;
    }

    // Simply sets the new token
    setToken(token) {
        if (token) {
            this.token = token;
            this.axios = axios.create({
                "baseURL": this.baseURL.concat('api/v1/'),
                "headers": {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'x-api-key': this.xApiKey,
                    'Authorization': 'Bearer '.concat(token)
                }
            });
        }
    }

    /**
     * @returns {String} returns the current jwtToken
     */
    getToken() {
        return this.token;
    }

    /**
    * @returns {String} returns the currently set environment
    */
    getEnvironment() {
        return this.environment;
    }

    /**
    * @returns {String} returns the currently set program
    */
    getProgram() {
        return this.program;
    }

    /**
     * @param {String} endpoint api endpoint suffix
     * @param {Object} data json body
     * @param {Function} callbackSuccess callback function
     * @param {Function} callbackFailed callback function
     */
    post(endpoint, data, callbackSuccess, callbackFailed) {
        var self = this;
        self.axios.post(endpoint, data)
            .then(function (response) {
                self.handleSuccess(response, callbackSuccess);
            })
            .catch(function (error) {
                self.handleError(error, callbackFailed);
            });
    }

    /**
     * @param {String} endpoint api endpoint suffix
     * @param {Object} data json body
     * @param {Function} callbackSuccess callback function
     * @param {Function} callbackFailed callback function
     */
    postLocal(endpoint, data, callbackSuccess, callbackFailed) {
        var self = this;
        self.axios.post(endpoint, data)
            .then(function (response) {
                self.handleSuccess(response, callbackSuccess);
            })
            .catch(function (error) {
                self.handleError(error, callbackFailed);
            });
    }

    get(endpoint, params, callbackSuccess, callbackFailed) {
        var self = this;
        this.axios.get(endpoint)
            .then(function (response) {
                self.handleSuccess(response, callbackSuccess);
            })
            .catch(function (error) {
                self.handleError(error, callbackFailed)
            });
    }

    postAttachment(endpoint, data, callbackSuccess, callbackFailed) {
        var self = this;
        var attachment = axios.create({
            "baseURL": this.baseURL.concat('api/v1/'),
            "headers": {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json',
                'x-api-key': this.xApiKey,
                Authorization: 'Bearer '.concat(this.token)
            }
        });
        attachment.post(endpoint, data)
            .then(function (response) {
                self.handleSuccess(response, callbackSuccess);
            })
            .catch(function (error) {
                self.handleError(error, callbackFailed)
            });
    }

    getAttachment(endpoint, data, callbackSuccess, callbackFailed) {
        // var self = this;

        // var MyURL = this.baseURL.concat('api/v1/'+endpoint)
        // console.log("CHECKURL:",MyURL);

        // fetch(
        //     MyURL,{
        //         crossDomain:true,
        //         mode: 'cors'
        //         method: 'GET',
        //         headers: {
        //             'x-api-key': this.xApiKey,
        //             Authorization: 'Bearer '.concat(this.token)
        //         },
        //         data:{
        //             responseType: 'blob'
        //         }
        //     })
        // .then(function (response) {
        //     console.log("SUCCESS", response)
        //     self.handleSuccess(response);
        //     callbackSuccess(response.data);
        // }).catch(function (error) {
        //     console.log("!!!!!", error)
        //     self.handleError(error, callbackFailed)
        // });

        var self = this;
        var attachment = axios.create({
            "baseURL": this.baseURL.concat('api/v1/'),
            "headers": {
                'x-api-key': this.xApiKey,
                Authorization: 'Bearer '.concat(this.token)
            }
        });

        // var concatParams = {
        //     ...data,
        //     crossDomain:true
        // }

        // console.log(">>><<<>>>><<<>>>><<<>>>>", concatParams)

        attachment.get(endpoint, data)
            .then(function (response) {
                self.handleSuccess(response);
                callbackSuccess(response.data);
            })
            .catch(function (error) {
                self.handleError(error, callbackFailed)
            });
    }

    handleSuccess(response, callbackSuccess) {
        if (response.data.token) {
            this.setToken(response.data.token);
            this.tokenUpdated(response.data.token);
        }
        if (callbackSuccess instanceof Function) {
            callbackSuccess(response.data.data);
        }
    }

    handleError(error, callbackFailed) {
        if (error.hasOwnProperty('response')) {
            if (callbackFailed instanceof Function) {
                if (error.hasOwnProperty('response')) {
                    switch (error.response.status) {
                        case 401:
                            callbackFailed(new JudeError(error.response));
                            break;
                        case 403:
                            callbackFailed(new JudeError(error.response));
                            break;
                        case 400:
                        case 404:
                        case 422:
                            callbackFailed(new JudeError(error.response));
                            break;
                        case 500:
                            callbackFailed(new JudeError(null));
                            break;
                        default:
                            console.log('Unknown Error');
                    }
                } else {
                    callbackFailed(new JudeError(error.response));
                }
            }
        }

    }

    /**
     * Attempts to refresh the jwtToken if it is invalid. Not used.
     * @returns {Promise} promise
     */
    refreshIfInvalid() {
        var self = this;
        return new Promise((resolve, reject) => {
            if (!isTokenValid(this.token)) {
                console.log('calling refresh invalid token');
                this.axios.get('auth/refresh').then(function (response) {
                    if (response.data.token) {
                        self.setToken(response.data.token);
                        self.tokenUpdated(response.data.token);
                    }
                    console.log('Token Refreshed');
                    resolve('token_refreshed');
                }).catch(function (error) {
                    reject(error.response.errors);
                });
            } else {
                resolve('valid_token');
            }
        });
    }

}