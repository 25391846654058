import ConnectionHub from '../Connect/ConnectionHub';

class BaseModel {

  constructor(props){
    this.data = {}
    this.connectionHub = new ConnectionHub();
  }

  addConnection(key,method){
    this.connectionHub.addConnection(key,method);
  }

  removeConnection(key,method){
    this.connectionHub.removeConnection(key,method);
  }

  dispatch(key,data){
    this.connectionHub.dispatch(key,data);
  }

  populateData(data){

  }

  getData(){
    return this.data;
  }

}

export default BaseModel