import React from 'react';
import { Jude } from '../HeyJudeSDK'

class HeyJudeUtils{

  static instance;

  static JudeManager(){
    if(HeyJudeUtils.instance === undefined){
      //HeyJudeUtils.instance = new Jude('live', 'mimo', 'Pn7j80P6gMGo19uBk8w73vg97FCyt3WKduujex74lh0g3466kH9m509806D8SWox');
      // HeyJudeUtils.instance = new Jude('local', 'heyjude', '1234567890');
      HeyJudeUtils.instance = new Jude('staging', 'heyjude', '1234567890');
    }
    return HeyJudeUtils.instance;
  }
}

export default HeyJudeUtils;