import React from 'react';

import TitleBar from '../../Components/Screen/TitleBar/index';
import TasksView from '../../Components/Screen/TasksView';
import NewTaskInput from '../../Components/Screen/NewTaskInput';

import SocketControl from '../../Control/SocketControl';
import style from './mainApp.module.scss';
import theme from '../../Theme/theme.module.scss';

class MainApp extends React.PureComponent{
  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);

    this.onPageFocus = this.onPageFocus.bind(this);
    this.onPageBlur = this.onPageBlur.bind(this);
  }


  componentDidMount(){
    SocketControl.getInstance();

    window.addEventListener('focus', this.onPageFocus);
    window.addEventListener('blur', this.onPageBlur);
  }

  componentWillUnmount(){
    // console.log("UN MOUNTING THIS SHIT")
    window.removeEventListener('focus', this.onPageFocus);
    window.removeEventListener('blur', this.onPageBlur);
  }

  onPageFocus(){
    SocketControl.getInstance().setNotify(false)
    SocketControl.getInstance().reconnect();
  }

  onPageBlur(){
    SocketControl.getInstance().setNotify(true)
  }

  render(){
      return(
        <div className={`${theme[this.props.theme]} wrapper-main-app`} key="MainAppInner">
         <TitleBar theme={this.props.theme} key="TitleBar"/>
         <TasksView theme={this.props.theme} key="TasksView"/>
         <NewTaskInput theme={this.props.theme}/>
        </div>
      )
  }

}

export default MainApp;