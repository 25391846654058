import React from 'react';
import PaymentOverlay from "../../../Overlay/PaymentOverlay"
import UserControl from "../../../../Control/UserControl";
import Button from "../../../Button";

class MessageTypePayment extends React.PureComponent{

  constructor(props) {
    super(props);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let selectedId = params.get('custom_param2');
    if(selectedId !== null && selectedId == this.props.data.paymentRequestId){
      this.makePayment();
    }
  }

  makePayment = async () => {
    window.history.replaceState({}, document.title, "/");
    let response = await UserControl.getInstance().getUserPaymentMethods();
    let paymentMethods = response.payment_methods;
    let paymentRequestId = this.props.data.paymentRequestId;
    let paymentRequestDetails = this.props.data;
    let taskId = this.props.data.taskId;
    this.showPayment(paymentMethods, paymentRequestId, paymentRequestDetails, taskId);
  };

  showPayment(paymentMethods, paymentRequestId, paymentRequestDetails, taskId){
    PaymentOverlay.getInstance().show({
      show:true,
      paymentMethods: paymentMethods,
      paymentRequestId: paymentRequestId,
      paymentRequestDetails: paymentRequestDetails,
      taskId: taskId,
      cancelText:"Cancel"
    });
  }

  render(){
    let { supplier, description, currency, status } = this.props.data;
    let payButton = "";
    if(status === "pending"){
      payButton = <Button id={'payNowButton_' + this.props.data.paymentRequestId} className={'pay-now mt-1-rem'} text={'Pay Now'} onClick={this.makePayment}></Button>
    }
    return(
      <div className="PaymentContainer">
        <div className="PaymentTitle">{supplier}</div>
        <div className="PaymentDetailsContainer">
          <div className="PaymentDetails">
            <div>{description}</div>
          </div>
          <div className="PaymentStatusContainer">
            <div className="PaymentAmount">{currency} {this.props.data.amount}</div>
            <div id={'paymentStatus_' + this.props.data.paymentRequestId} ref={'paymentStatus_' + this.props.data.paymentRequestId} className="PaymentStatus">{status}</div>
            {payButton}
          </div>
        </div>
      </div>
    )
  }

}

export default MessageTypePayment;