import MediaModel from "../Model/MediaModel";
import Utils from "../Utils/Utils";

class MediaControl { 
  static getInstance(){
    if(MediaControl.instance === undefined){
      MediaControl.instance = new MediaControl();
    }
    return MediaControl.instance;
  }

  constructor(){
    this.fileReferences = {};
    this.limit = 0;
    this.count = 0;

    this.addMediaToUploadSet = this.addMediaToUploadSet.bind(this);
    this.addToUploadFileReference = this.addToUploadFileReference.bind(this);
    this.addMediaToNewTaskUpload = this.addMediaToNewTaskUpload.bind(this);
    this.addToNewTaskUploadFileReference = this.addToNewTaskUploadFileReference.bind(this);
    this.clear = this.clear.bind(this);
    this.convertToBase64 = this.convertToBase64.bind(this);
  }

  addMediaToUploadSet(files){
    this.fileReferences = {}
    this.count = 0;
    this.limit = files.length;

    for (var file of files){
      if(!file.type.includes('image')){
        let id = file.lastModified + "_"+ file.size;
        var fileData = {
          file:file,
          id:id,
          type:file.type,
          url:URL.createObjectURL(file),
        }
        MediaControl.getInstance().addToUploadFileReference(fileData);
      }else{
        Utils.prepareImageForUpload(file,MediaControl.getInstance().addToUploadFileReference)
      }
    }

    //||||||||||||||||||||||||||||||||||||||||||||||||
    // var fileReferences = {}
    // for(var file of files){
    //   let id = file.lastModified + "_"+ file.size;
    //   fileReferences[id]={
    //     file:file,
    //     id:id,
    //     type:file.type,
    //     url:URL.createObjectURL(file),
    //   }
    // }
    // MediaModel.getInstance().addMediaToUploadSet(fileReferences)
    //||||||||||||||||||||||||||||||||||||||||||||||||
  }

  addToUploadFileReference(fileData){
    this.fileReferences[fileData.id] = fileData;
    if(this.count < this.limit-1){
      this.count++
    }else{
      MediaModel.getInstance().addMediaToUploadSet(this.fileReferences)
    }
  }

  addMediaToNewTaskUpload(files){
    this.fileReferences = {}
    this.count = 0;
    this.limit = files.length;

    for (var file of files){
      if(!file.type.includes('image')){
        let id = file.lastModified + "_"+ file.size;
        var fileData = {
          file:file,
          id:id,
          type:file.type,
          url:URL.createObjectURL(file),
        }
        MediaControl.getInstance().addToNewTaskUploadFileReference(fileData);
      }else{
        Utils.prepareImageForUpload(file,MediaControl.getInstance().addToNewTaskUploadFileReference)
      }
    }
    //||||||||||||||||||||||||||||||||||||||||||||||||
    // var fileReferences = {};
    // for(var file of files){
    //   let id = file.lastModified + "_"+ file.size;
    //   fileReferences[id]={
    //     file:file,
    //     id:id,
    //     type:file.type,
    //     url:URL.createObjectURL(file),
    //   }
    // }
    // MediaModel.getInstance().addMediaToNewTaskUpload(fileReferences)
    //||||||||||||||||||||||||||||||||||||||||||||||||
  }

  addToNewTaskUploadFileReference(fileData){
    this.fileReferences[fileData.id] = fileData;
    if(this.count < this.limit-1){
      this.count++
    }else{
      MediaModel.getInstance().addMediaToNewTaskUpload(this.fileReferences)
    }
  }

  clear(){
    MediaModel.getInstance().clear();
  }

  convertToBase64(data,callBack){
    var reader = new FileReader();
    reader.onload = function(){
      callBack(reader.result.toString());
    }
    reader.readAsDataURL(data);
  }

}

export default MediaControl;