import BaseModel from "./BaseModel";
import ConnectionType from "../Connect/ConnectionType";

class SocketModel extends BaseModel{

  static SOCKET_CONNECTED = "connected";
  static SOCKET_DISCONNECTED = "disconnected";

  static getInstance(){
    if(SocketModel.instance === undefined){
      SocketModel.instance = new SocketModel();
    }
    return SocketModel.instance;
  }

  constructor(){
    super();
    this.data.socketStatus = SocketModel.SOCKET_DISCONNECTED;
  }

  updateSocketStatus(status){
    this.data.socketStatus = status;
    this.dispatch(ConnectionType.TYPE_SOCKET_STATUS_UPDATE, status);
  }
}

export default SocketModel;