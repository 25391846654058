import React from 'react';
import HeyJudeUtils from '../../../../Utils/HeyJudeUtils';
import MediaControl from '../../../../Control/MediaControl'
import MessageControl from '../../../../Control/MessageControl';
import Utils from '../../../../Utils/Utils';

class MessageTypeImage extends React.PureComponent{

  constructor(props){
    super(props)

    this.state = {
      resendOnSend:false,
      resendOnUpload:false,
      loading:(this.props.data.pending)
                ? false 
                : true,
      src: (this.props.data.pending)
             ? this.props.data.pendingURL
             : undefined
    }

    this.renderResend = this.renderResend.bind(this);
    this.uploadAttachment = this.uploadAttachment.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.resendMessage = this.resendMessage.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
    this.downloadConvertedToURL = this.downloadConvertedToURL.bind(this);
  }

  componentDidMount(){
    if(this.props.data.pending){
      this.uploadAttachment();
      return;
    }

    this.downloadAttachment();
  }

  async uploadAttachment(){
    var uploadResponse = await MessageControl.getInstance().uploadAttachment(this.props.data.pendingFile)
    this.setState({resendOnUpload:!uploadResponse.result})

    if(!uploadResponse.result){
      return
    }
    this.attachmentId = uploadResponse.data;
    this.sendMessage();
  }

  async sendMessage(){
    var messageResponse = await MessageControl.getInstance().sendMessage({
      type:'image',
      taskId:this.props.data.taskId,
      attachmentId:this.attachmentId
    })

    this.setState({resendOnSend:!messageResponse})
  }

  resendMessage(){
    if(this.state.resendOnUpload){
      this.uploadAttachment();
    }else{
      this.sendMessage();
    } 

    this.setState({
      resendOnSend:false,
      resendOnUpload:false,
    })
  }

  async downloadAttachment(){
    var response = await HeyJudeUtils.JudeManager().Attachments.download(this.props.data.attachmentId);
    MediaControl.getInstance().convertToBase64(response,this.downloadConvertedToURL);
  }

  async downloadConvertedToURL(result){
    this.setState({src : result, loading:false})
  }

  renderResend(){
    if(!this.state.resendOnSend && !this.state.resendOnUpload){
      return null;
    }
    
    return(
      <div>
        <button className="ErrorUIButton" onClick={this.resendMessage}>
          Error, press to resend
        </button>
      </div>
    )
  }

  render(){
    if(this.state.loading){
      return(
        <div className="MediaThumbnail">
        <img className="MediaPlaceHolder" src={this.state.src} alt="placeHolder"/>
          <div className="AlertLoader"></div>
          <div className="AlertLoaderText">{this.state.message}</div>
        </div>
      )
    }

    return(
      [
        <img className="MediaThumbnail" src={this.state.src} alt="placeHolder"/>,
        this.renderResend()
      ]
    );
  }
}

export default MessageTypeImage;