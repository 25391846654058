import firebase from 'firebase';
import 'firebase/messaging';

const vapid = 'BHKXgGCUuiYxtxfYwGyNdBxQVhGvG_U8cShCDlHEmJ-y8hIsisQt-XHycWVG-aV_HXKwangmmhxPCc5iywTb4lY';

export const firebaseInit = () => {
    firebase.initializeApp({
        apiKey: "AIzaSyCZWNQoor-hvSVzOUZMYAbIi1jt1cH1dnA",
        authDomain: "heyjude-718fa.firebaseapp.com",
        databaseURL: "https://heyjude-718fa.firebaseio.com",
        projectId: "heyjude-718fa",
        storageBucket: "heyjude-718fa.appspot.com",
        messagingSenderId: "441890123674",
        appId: "1:441890123674:web:fc696a7ba1833fdd89d423"
    })
    
    if(firebase.messaging.isSupported()){
        const messaging = firebase.messaging();
        messaging.usePublicVapidKey(vapid);

        navigator.serviceWorker.register('/firebase-messaging-sw.js')

        messaging.requestPermission()
            .then(()=>{
                messaging.getToken()
                    .then((token)=>{
                    })
                    .catch((err)=>{
                        console.error(err);
                    })
            })
            .catch((err)=>{
                console.error(err);
            })
    }

};
