
import { ApiHelper } from '../Helpers/ApiHelper';
import { AttachmentDetail } from '../Models/';

export default class Attachments extends ApiHelper {

    constructor(environment, program, xApiKey, callbackTokenUpdated) {
        super(environment, program, xApiKey, callbackTokenUpdated);
    }

    /**
     * Uploads an attachment
     * @param {Object} file 
     * @param {String} file.uri path to file
     * @param {String} file.mimeType eg. 'image/jpeg'
     * @param {String} file.name eg. 'IMG_0003.JPG'
     */
    upload(file) {
        const formData = new FormData();
        formData.append('attachment', file);

        return new Promise((resolve, reject) => {
            super.postAttachment('attachments/upload', formData, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }
    
    /**
     *  Download attachement
     * @param {String} attachmentId id of the attachment you would like to download
     * @returns {Blob} data is returned as a blob
     */
    download(attachmentId) {
        return new Promise((resolve, reject) => {
            super.getAttachment('attachments/download/'.concat(attachmentId), {
                responseType: 'blob'
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }


    details(attachmentId) {
        return new Promise((resolve, reject) => {
            super.get('attachments/detail/'.concat(attachmentId), {
            }, function (data) {
                console.log(data);
                resolve(new AttachmentDetail(data.attachment));
            }, function (data) {
                reject(data);
            });
        });
    }

}