

export default class JudeError {

    constructor(json) {
        this.errors = ['Oops something went wrong'];
        this.message = 'Opps something went wrong';
        this.status = 'unknown';
        if (json) {
            this.errors = json.data.errors;
            this.message = json.data.message;
            this.status = json.status;
        }
    }

    toString() {
        return this.errors.toString();
    }
}