

export default class Payment {

    constructor(data) {
        this.id = data.id ? data.id : null;
        this.cardBrand = data.card_brand ? data.card_brand : null;
        this.card = data.card ? data.card : null;
        this.currency = data.currency ? data.currency : null;
        this.date = data.date ? data.date : null;
        this.description = data.description ? data.description : null;
        this.status = data.status ? data.status : null;
        this.supplier = data.supplier ? data.supplier : null
    }

    toString() {
        return "SubscriptionOption Object: "
            .concat(' id: ').concat(this.id)
            .concat(' cardBrand: ').concat(this.cardBrand)
            .concat(' card: ').concat(this.card)
            .concat(' currency: ').concat(this.currency)
            .concat(' description: ').concat(this.description)
            .concat(' status: ').concat(this.status)
            .concat(' cardHolder: ').concat(this.cardHolder)
    }
}
