import React from 'react';
import './AlertMessage.scss';

import theme from '../../Theme/theme.module.scss';
import Button from '../Button';


const defaultState = {
  show:false,
  title:'',
  message:'',
  acceptText:'Accept',
  acceptCallBack:undefined,
  cancelText:undefined,
  cancelCallBack:undefined,
}

class AlertMessage extends React.PureComponent{

  static defaultProps = {
      theme: 'default'
  };

    static setInstance(instance){
      AlertMessage.instance = instance;
    } 

    static getInstance(instance){
      return AlertMessage.instance;
    }

    constructor(){
      super();
      this.state = defaultState;
      this.singleButtonMode = (defaultState.cancelText==="");

      this.onAcceptClick = this.onAcceptClick.bind(this);
      this.onCancelClick = this.onCancelClick.bind(this);
      this.hide = this.hide.bind(this);
      this.show = this.show.bind(this);
      this.getButtonMode = this.getButtonMode.bind(this);
      this.renderCancelButton = this.renderCancelButton.bind(this);

      AlertMessage.setInstance(this);
    }

    onAcceptClick(e){
      this.hide();

      if(this.state.acceptCallBack !== undefined){
        this.state.acceptCallBack();
      }
    }

    onCancelClick(e){
      this.hide();

      if(this.state.cancelCallBack !== undefined){
        this.state.cancelCallBack();
      }
    }

    show(options){
      this.singleButtonMode = (options.cancelText=== undefined);

      this.setState ({
        ...defaultState,
        ...options
      })
    }

    hide(){
      this.setState({...defaultState})
    }

    renderCancelButton(){
      if(this.state.cancelText === undefined){
        return null;
      }
      return (
        <span><button className="AlertMessageButtonCancel" type="button" onClick={this.onCancelClick}>{this.state.cancelText}</button></span>
      )
    }

    getButtonMode(){
      if(this.singleButtonMode){
        return "AlertMessageButtonAcceptSingle"
      }else{
        return "AlertMessageButtonAccept"
      }
    }

    render(){
      if(!this.state.show){
        return null;
      }

      return(
        <div className={`${theme[this.props.theme]} AlertMessagePage`}>
          <div className={`${theme[this.props.theme]} AlertMessageContainer`}>
            <div className="AlertMessageTitle">{this.state.title}</div>
            <div className="AlertMessageText">{this.state.message}</div>
            <div className="AlertMessageButtonContainer">
              {this.renderCancelButton()}
                <Button
                  theme={this.props.theme}
                  onClick={this.onAcceptClick}
                  text={this.state.acceptText}
                  className="fz-15 w-100 no-radius"
                ></Button>
            </div>
          </div>
        </div>
      )
    }


}

export default AlertMessage;