import Task from './Task';

export default class Tasks {

    constructor(json) {
        this.count = json.count ? json.count : null;
        this.limit = json.limit ? json.limit : null;
        this.offset = json.offset ? json.offset : null;
        this.order = json.order ? json.order : null;
        this.total = json.total ? json.total : null;
        this.tasks = json.tasks ? mapTasks(json.tasks) : [];
    }

    toString() {
        return "Tasks Object: "
            .concat(' count: ').concat(this.count)
            .concat(' limit: ').concat(this.limit)
            .concat(' offset: ').concat(this.offset)
            .concat(' order: ').concat(this.order)
            .concat(' total: ').concat(this.total)
            .concat(' tasks: ').concat(this.tasks)
    }
}

const mapTasks = (array) => {
    var tasksArray = [];
    array.forEach(function (item) {
        var taskItem = new Task(item);
        tasksArray.push(taskItem);
    });
    return tasksArray;
}