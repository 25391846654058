import { ApiHelper } from '../Helpers/ApiHelper';
import { User as UserModel } from '../Models/';
import axios from 'axios';
import qs from 'qs'

export default class User extends ApiHelper {

    constructor(environment, program, xApiKey, callbackTokenUpdated) {
        super(environment, program, xApiKey, callbackTokenUpdated);
    }

    /**
     * @returns {Object} User object
     */
    getProfile() {
        return new Promise((resolve, reject) => {
            super.get('users/profile', {
            }, function (data) {
                const user = new UserModel(data.user);
                resolve(user);
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * @param {Object} details  User details object
     * @param {String} details.lastName last name
     * @param {String} details.firstName first name
     * @param {String} details.email email
     * @param {FormData} details.profile_image profile image
     * @param {Boolean} details.greenerChoices does user perfers environment friendly options
     * @param {Boolean} details.pushNotifications are push notifications enabled
     * @param {String} details.password
     * @param {String} details.passwordConfirmation 
     */
    updateProfile(details) {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append('profile_image', details.profileImage);
            formData.append('email', details.email);
            formData.append('last_name', details.lastName);
            formData.append('greener_choices', details.greenerChoices);
            formData.append('push_notifications', details.pushNotifications);
            formData.append('first_name', details.firstName);
            super.post('users/profile', formData, function (data) {
                const user = new UserModel(data.user);
                resolve(user);
            }, function (data) {
                reject(data);
            });
        });
    }

    pay(paymentRequestId, paymentMethodId) {
        return new Promise((resolve, reject) => {
            super.post('payments/pay', {
                'payment_request_id': paymentRequestId,
                'payment_method_id': paymentMethodId
            }, function (response) {
                resolve(response);
            }, function (data) {
                reject(data);
            });
        });
    }

    createPaymentLinks(custom_params) {
        let data = null;
        if(custom_params !== null){
            data = custom_params
        } else {
            data = {
                'provider': 'peach',
            };
        }
        return new Promise((resolve, reject) => {
            super.post('payment-links/create', data, function (response) {
                resolve(response);
            }, function (data) {
                reject(data);
            });
        });
    }

    getPaymentMethods(){
        return new Promise((resolve, reject) => {
            super.get('payments/methods', {
            }, function (response) {
                resolve(response);
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * 
     * @param {String} type which type road block should be skipped eg. 'tutorial'
     */
    skipRoadBlock(type) {
        return new Promise((resolve, reject) => {
            super.post('users/skip-roadblock', {
                "roadblock": type
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }

}