import React from 'react';

import TasksOpen from '../TasksOpen';
import TasksClosed from '../TasksClosed';
import TaskModel from '../../../../Model/TaskModel';
import ConnectionType from '../../../../Connect/ConnectionType';


import style from './taskListContainer.module.scss';
import theme from '../../../../Theme/theme.module.scss';



class TaskListContainer extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  static TYPE_OPEN = "open";
  static TYPE_CLOSED = "closed";

  constructor(props){
    super(props);

    this.updateTaskType = this.updateTaskType.bind(this);

    this.state={
      taskType:TaskListContainer.TYPE_OPEN,
    }
  }

  componentWillMount(){
    TaskModel.getInstance().addConnection(ConnectionType.TYPE_TASK_TYPE_SELECTED, this.updateTaskType)
  }

  componentWillUnmount(){
    TaskModel.getInstance().removeConnection(ConnectionType.TYPE_TASK_TYPE_SELECTED, this.updateTaskType)
  }

  updateTaskType(type){
    this.setState({taskType:type})
  }

  render(){
    if(this.state.taskType === TaskListContainer.TYPE_OPEN){
      return (<TasksOpen theme={this.props.theme} key="TaskListContainerOpen"/>)
    }
    return (<TasksClosed theme={this.props.theme} key="TaskListContainerClosed"/>)
  }

}

export default TaskListContainer;