
import PaymentMethod from './PaymentMethod';

export default class PaymentMethods {

    constructor(json) {
        console.log('Payment Methods '.concat(json));
        this.methods = json.payment_methods ? mapPaymentMethods(json.payment_methods) : null;
    }

    toString() {
        return "Payment methods: ".concat(this.methods);
    }
}

const mapPaymentMethods = (array) => {
    var methodsArray = [];
    array.forEach(function (item) {
        var methodItem = new PaymentMethod(item);
        methodsArray.push(methodItem);
    });
    return methodsArray;
}