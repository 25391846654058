import BaseModel from "./BaseModel";
import TaskModel from "./TaskModel";
import ConnectionType from "../Connect/ConnectionType";

class MessageModel extends BaseModel{

  static getInstance(){
    if(MessageModel.instance === undefined){
      MessageModel.instance = new MessageModel();
    }
    return MessageModel.instance;
  }

  constructor(){
    super();
    this.data={};
  }

  populateData(taskId,data){
    this.data[taskId] = data;
    this.dispatch(ConnectionType.TYPE_MESSAGE_DATA,{id:taskId, data:data})
  }

  clearMessages(){
    this.dispatch(ConnectionType.TYPE_MESSAGE_CLEAR)
  }

  addAssumedSuccessMessages(taskId,data){
    var currentTaskMessages = this.data[taskId].messages || []
    
    for(var dataItem of data){
      currentTaskMessages.push(dataItem)
    }

    this.dispatch(ConnectionType.TYPE_MESSAGE_DATA,{id:taskId, data:this.data[taskId]})
  }

  addReceivedSocketMessages(taskId,data){
    var taskData = this.data[taskId];
    if(taskData === undefined){
      taskData = {messages:[data]}
      this.data[taskId] = taskData;
    }else{
      taskData.messages.push(data);
    }

    this.dispatch(ConnectionType.TYPE_SOCKET_MESSAGE_DATA,{id:taskId, data:this.data[taskId]})

  }

}

export default MessageModel;