import React from 'react';
import InputText from '../../Components/InputText/index';

import UserControl from '../../Control/UserControl';
import UserModel from '../../Model/UserModel';
import ConnectionType from '../../Connect/ConnectionType';
import User from '../../HeyJudeSDK/User';
import AlertMessage from '../../Components/Overlay/AlertMessage';

import Button from '../../Components/Button/index';
import style from './forgotPassword.module.scss';
import theme from '../../Theme/theme.module.scss';

class ForgotPassword extends React.PureComponent{
  static defaultProps = {
    theme: 'default'
  };


  // static appear;

  constructor(props){
    super(props);
    this.state ={
      dialingCodes:undefined,
      hasOTP:false,
      willMount: false,
      willUnmount: false,
      appear: style['appear']
    }

    // this.appear = (!this.state.willMount) ? style['appear'] : '';

    this.codeSelector = React.createRef();

    this.renderDialingCodes = this.renderDialingCodes.bind(this);
    this.renderConfirmation = this.renderConfirmation.bind(this);
    this.getDialingCodes = this.getDialingCodes.bind(this);
    this.closeWindow = this.closeWindow.bind(this);

    this.getOTP = this.getOTP.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
    this.validateConfirmation = this.validateConfirmation.bind(this);
    this.appendError = this.appendError.bind(this);
  }

  componentDidMount(){
    this.getDialingCodes()
  }

  closeWindow(){
    UserModel.getInstance().dispatch(ConnectionType.TYPE_USER_FORGOT_PASSWORD,{show:false})
  }

  async getDialingCodes(){
    let response = await UserControl.getInstance().getDialingCodes()
    if(response.result){
      this.setState({dialingCodes:response.data})
    }
  }

  validatePhoneNumber(){
    let valid = true
    let message = ""
    
    if(this.fldMobile.getCurrentValue().length === 0){
      message = "Please enter your Mobile Number"
      valid = false;
    }

    if(!valid){
      AlertMessage.getInstance().show({
        show:true,
        title:'Error',
        message:message,
      })
    }

    return valid
  }

  validateConfirmation(){
    let valid = true;
    let message = ""

    if(this.fldOtp.getCurrentValue().length < 6){
      valid = false;
      message = this.appendError(message, "Please enter your 6 character OTP")
    }

    if(this.fldPassword.getCurrentValue().length < 6){
      valid = false;
      message = this.appendError(message, "Password must be 6 characters or more.")
    }else if(this.fldPassword.getCurrentValue() !== this.fldPasswordConfirm.getCurrentValue()){
      valid = false;
      message = this.appendError(message, "Passwords must match")
    }

    if(!valid){
      AlertMessage.getInstance().show({
        show:true,
        title:'Error',
        message:message
      })
    }

    return valid;
  }

  appendError(cValue, nValue){
    let value = "";
    if(cValue !== ""){
      value = cValue + "\n"
    }
    value += nValue;
    return value;
  }

  async getOTP(){
    if(!this.validatePhoneNumber()){ return; }

    this.finalNumber = this.codeSelector.current.options[this.codeSelector.current.selectedIndex].value + "" + this.fldMobile.getCurrentValue()
    let response = await UserControl.getInstance().getOTP(this.finalNumber)

    if(response.result){
      this.setState({hasOTP:true})
    }
  }

  async resetPassword(){
    if(!this.validateConfirmation()){
      return;
    }

    await UserControl.getInstance().resetPassword(
      this.fldOtp.getCurrentValue(),
      this.fldPassword.getCurrentValue(),
      this.finalNumber
    )
  }

  renderDialingCodes(){
    if(this.state.dialingCodes === undefined){
      return null;
    }

    let displayList = [];
    for (let code of this.state.dialingCodes){
      let finalCode = "+" + code.phonecode;
      displayList.push(<option value={finalCode} key={code.phonecode}>{code.name}</option>)
    }
    console.log(displayList)
    return displayList;
  }

  componentWillMount(){
    this.state.appear = style['appear'];
  }
  componentWillUnmount(){
    this.state.appear = style['disappear'];
  }
  

  motionClass(){
    if(this.state.willMount){
      return 'appear';
    }
    if(this.state.willUnmount){
      return 'disappear';
    }
    return '';
  }

  renderGetOTP(){
    if(this.state.hasOTP){
      return null;
    }

    return(
      <React.Fragment>
          <select className={`${style.FPSelect} mt-30`} ref={this.codeSelector}>
            {this.renderDialingCodes(this.state.data)}
          </select>
          <InputText
            theme={this.props.theme}
            setReference={(ref)=>this.fldMobile=ref}
            defaultValue=""
            id="username"
            placeholder="Mobile Number"
            type="tel"
            className='mt-15'
          />
        <Button onClick={this.getOTP} text="Send OTP" theme={this.props.theme} className="uppercase mt-30"></Button>
      </React.Fragment>
    )
  }

  renderConfirmation(){
    if(!this.state.hasOTP){
      return null;
    }

    return(
      <React.Fragment>
        <InputText
          theme={this.props.theme}
          setReference={(ref)=>this.fldOtp=ref}
          defaultValue=""
          id="otp"
          placeholder="OTP"
          type="tel"
          className='mt-30'
        />
        <InputText
          theme={this.props.theme}
          setReference={(ref)=>this.fldPassword=ref}
          defaultValue=""
          id="password"
          placeholder="Password"
          type="password"
          className='mt-15'
        />
        <InputText
          theme={this.props.theme}
          setReference={(ref)=>this.fldPasswordConfirm=ref}
          defaultValue=""
          id="passwordConfirm"
          placeholder="Confirm Password"
          type="password"
          className='mt-15'
        />
        <Button onClick={this.resetPassword} text="Confirm" theme={this.props.theme} className="uppercase mt-30"></Button>
      </React.Fragment>
    )
  }

  render(){
    return(
      <div className={`${style.FPContainer} ${theme[this.props.theme]}`}>
        <div className={`${style.FPBox} ${this.state.appear}`}>
          <div className={`${style.FPClose}`}>
            <Button
              onClick={this.closeWindow}
              theme={this.props.theme}
              textonly="true"
              className="fz-15 p-5 icon-cancel"
            ></Button>
          </div>
          <h3 className="text fz-20 color-black">Reset your password</h3>
          {this.renderGetOTP()}  
          {this.renderConfirmation()}
        
        </div>
      </div>
    )
  }

}

export default ForgotPassword;