import React from 'react';
import MessageControl from '../../../../Control/MessageControl';

class MessageTypeText extends React.PureComponent{

  constructor(props){
    super(props);
    this.sendMessage = this.sendMessage.bind(this);
    this.attempts = 0;
    this.state = {
      resend : false
    }

    this.renderResend = this.renderResend.bind(this);
    this.resendMessage = this.resendMessage.bind(this);
  }

  componentDidMount(){
    if(this.props.data.pending){
      this.sendMessage();
    }
  }

  resendMessage(){
    this.setState({resend:false});
    this.sendMessage();
  }

  async sendMessage(){
    var result = await MessageControl.getInstance().sendMessage({
      type:'text',
      text:this.props.data.text,
      taskId:this.props.data.taskId,
    });

    if(!result){
      this.setState({
        resend:true,
      })
    }
  }

  renderResend(){
    if(!this.state.resend){
      return null;
    }
    
    return(
      <div>
        <button onClick={this.resendMessage}>
          Error, press to resend
        </button>
      </div>
    )
  }

  render(){
    return (
      <div className="fz-16">
        {this.props.data.text}
        {this.renderResend()}
      </div>
    )
  }

}

export default MessageTypeText;