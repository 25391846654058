

export default class PaymentRequest {

    constructor(data) {
        this.id = data.id ? data.id : null;
        this.amountTotal = data.amount_total ? data.amount_total : null;
        this.currency = data.currency ? data.currency : null;
        this.details = data.details ? data.details : null;
        this.invoiceDescription = data.invoice_description ? data.invoice_description : null;
        this.status = data.status ? data.status : null;
    }

    toString() {
        return "Payment Request Object: "
            .concat(' id: ').concat(this.id)
            .concat(' amountTotal: ').concat(this.amountTotal)
            .concat(' currency: ').concat(this.currency)
            .concat(' details: ').concat(this.details)
            .concat(' invoiceDescription: ').concat(this.invoiceDescription)
            .concat(' status: ').concat(this.status)
    }
}
