
class Connection{

  constructor(key,method){
    this.key = key;
    this.method = method;
  }

  exists(key,method){
    return (this.key === key && this.method === method);
  }

  dispatch(key,data){
    if(this.key !== key){
      return
    }
    this.method(data);
  }

  destroy(){
    this.key = undefined;
    this.method = undefined;
  }
}

export default Connection