
import Payment from './Payment';

export default class Payments {

    constructor(json) {
        console.log('Payments '.concat(json));
        this.payments = json.payments ? mapPayments(json.payments) : null;
    }

    toString() {
        return "Payments: ".concat(this.payments);
    }
}

const mapPayments = (array) => {
    var paymentsArray = [];
    array.forEach(function (item) {
        var PaymentItem = new Payment(item);
        paymentsArray.push(PaymentItem);
    });
    return paymentsArray;
}