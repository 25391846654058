
import Message from './Message';

export default class Task {

    constructor(json) {
        this.id = json.id ? json.id : null
        this.messageCount = json.message_count ? json.message_count : null;
        this.messageLimit = json.message_limit ? json.message_limit : null;
        this.messageOffset = json.message_offset ? json.message_offset : null;
        this.messageOrder = json.message_order ? json.message_order : null;
        this.messageTotal = json.message_total ? json.message_total : null;
        this.messages = json.messages ? mapMessages(json.messages) : null;
        this.open = json.open ? json.open : null;
        this.status = json.status ? json.status : null;
        this.title = json.title ? json.title : null;
        this.createdAt = json.created_at ? json.created_at : null;
        this.unread = json.unread ? json.unread : null

        //note, this can be changed in ES6 to this.varName = json.paramName || null
    }

    toString() {
        return "Task Object: "
            .concat('id: ').concat(this.id)
            .concat(' messageCount: ').concat(this.messageCount)
            .concat(' messageLimit: ').concat(this.messageLimit)
            .concat(' messageOffset: ').concat(this.messageOffset)
            .concat(' messageOrder: ').concat(this.messageOrder)
            .concat(' messageTotal: ').concat(this.messageTotal)
            .concat(' messages: ').concat(this.messages)
            .concat(' open: ').concat(this.open)
            .concat(' status: ').concat(this.status)
            .concat(' title: ').concat(this.title)
            .concat(' createdAt: ').concat(this.createdAt)
            .concat('unread: ').concat(this.unread)
    }
}

const mapMessages = (array) => {
    var messagesArray = [];
    array.forEach(function (item) {
        var messageItem = new Message(item);
        messagesArray.push(messageItem);
    });
    return messagesArray;
}