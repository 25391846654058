import Connection from './Connection';


class ConnectionHub {
  
  constructor(){
    this.connections = [];
  }

  addConnection(key,method){    
    var connection = new Connection(key,method);
    this.connections.push(connection)
  }

  dispatch(key,data){
    for (var connection of this.connections){
      connection.dispatch(key,data);
    }
  }

  removeConnection(key,method){
    var count = 0;
    for (var connection of this.connections){
      if (connection.exists(key,method)){
        var breakingConnection = connection
        breakingConnection.destroy();
        breakingConnection = undefined;
        this.connections.splice(count,1);
        break;
      }
      count++;
    }
  }
}

export default ConnectionHub;