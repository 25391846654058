import React from 'react';
import './MModalButton.scss';
import MModal from '../MModal';

class MModalButton extends React.PureComponent{

	constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  outsideClick = (e) => {
    if (this.state.isOpen && e.target.tagName == 'ASIDE') {
      this.toggleModal();
    }
  }

  onKeyDown = (e) => {
    if (this.state.isOpen && e.keyCode === 27) {
      this.toggleModal();
    }
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <div>
        <a
          onClick={this.toggleModal}
          className="MModal_Button_Hey"
        >
          <strong>{this.props.title}</strong>
        </a>

        <MModal 
          show={this.state.isOpen}
          onClose={this.toggleModal}
          onOutsideClick={this.outsideClick}
          onKeyDown={this.onKeyDown}
        >
          {this.props.children}
        </MModal>
      </div>
    );
  }

}

export default MModalButton;