import KJUR, { b64utos } from 'jsrsasign';

export const isTokenValid = (token) => {
  if (token) {
    var a = token.split(".");
    var uHeader = b64utos(a[0]);
    var uClaim = b64utos(a[1]);

    var pHeader = KJUR.jws.JWS.readSafeJSONString(uHeader);
    var pClaim = KJUR.jws.JWS.readSafeJSONString(uClaim);

    //var sHeader = JSON.stringify(pHeader, null, "  ");
    //var sClaim = JSON.stringify(pClaim, null, "  ");

    var expiaryDate = convertUnixToDate(pClaim.exp);
    console.log('Token Expriary', expiaryDate);

    if (expiaryDate > new Date()) {
      console.log('isTokenValid: ', true);
      return true;
    }
  }
  return false;
}

const convertUnixToDate = (unixTimeStamp) => {
  return new Date(unixTimeStamp * 1000);
}
