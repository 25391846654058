

export default class PaymentMethod {

    constructor(data) {
        this.id = data.id ? data.id : null;
        this.lastFourDigits = data.last_four_digits ? data.last_four_digits : null;
        this.expiryMonth = data.expiry_month ? data.expiry_month : null;
        this.expiryYear = data.expiry_year ? data.expiry_year : null;
        this.brand = data.brand ? data.brand : null;
        this.default = data.default ? data.default : null;
        this.cardNickname = data.card_nickname ? data.card_nickname : null;
        this.cardHolder = data.card_holder ? data.card_holder : null;
    }

    toString() {
        return "PaymentMethod Object: "
            .concat(' id: ').concat(this.id)
            .concat(' lastFourDigits: ').concat(this.lastFourDigits)
            .concat(' expiryMonth: ').concat(this.expiryMonth)
            .concat(' expiryYear: ').concat(this.expiryYear)
            .concat(' brand: ').concat(this.brand)
            .concat(' cardNickname: ').concat(this.cardNickname)
            .concat(' cardHolder: ').concat(this.cardHolder)
    }
}
