
export default class User {

    constructor(json) {
        this.id = json.id;
        this.email = json.email;
        this.firstName = json.first_name;
        this.lastName = json.last_name;
        this.fullName = json.full_name;
        this.mobile = json.mobile;
        this.profileImage = json.profile_image;
        this.referralCode = json.referral_code;
        this.paymentProvider = json.payment_provider;
        this.country = json.country;
        this.pushNotifications = json.push_notifications;
        this.greenerChoices = json.greener_choices;
        this.roadblocks = json.roadblocks;
    }

    toString() {
        return "User Object: "
            .concat('id: ').concat(this.id)
            .concat(' email: ').concat(this.email)
            .concat(' firstName: ').concat(this.firstName)
            .concat(' lastName: ').concat(this.lastName)
            .concat(' fullName: ').concat(this.fullName)
            .concat(' mobile: ').concat(this.mobile)
            .concat(' profileImage: ').concat(this.profileImage)
            .concat(' referralCode: ').concat(this.referralCode)
            .concat(' paymentProvider: ').concat(this.paymentProvider)
            .concat(' country: ').concat(this.country)
            .concat(' greenerChoices: ').concat(this.greenerChoices)
            .concat(' roadblocks: ').concat(this.roadblocks)
    }

    // TODO: Filling up memory for some reason

    // get id() {
    //     return this.id;
    // }

    // get email() {
    //     return this.email;
    // }

    // get firstName() {
    //     return this.firstName;
    // }

    // get lastName() {
    //     return this.lastName;
    // }

    // get fullName() {
    //     return this.fullName;
    // }

    // get mobile() {
    //     return this.mobile;
    // }

    // get profileImage() {
    //     return this.profileImage;
    // }

    // get referralCode() {
    //     return this.referralCode;
    // }

    // get paymentProvider() {
    //     return this.paymentProvider;
    // }

    // get country() {
    //     return this.country;
    // }

    // get pushNotifications() {
    //     return this.pushNotifications;
    // }

    // get greenerChoices() {
    //     return this.greenerChoices;
    // }

    // get roadblocks() {
    //     return this.roadblocks;
    // }
}
