
import { ApiHelper } from '../Helpers/ApiHelper';
import { PaymentMethods, Payments, PaymentRequest } from '../Models/';

export default class Subscriptions extends ApiHelper {

    constructor(environment, program, xApiKey, callbackTokenUpdated) {
        super(environment, program, xApiKey, callbackTokenUpdated);
    }

    /**
     * This function returns the payment methods avaliable to the user
     * @returns {Promise}
     */
    methods() {
        return new Promise((resolve, reject) => {
            super.get('payments/methods', {
            }, function (data) {
                resolve(new PaymentMethods(data));
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * This function adds a users payment method
     * @param {String} lastFourDigits last four digits of card number
     * @param {String} provider eg. peach
     * @param {Boolean} isDefaultCard should be used as default for billing
     * @param {String} expiraryYear year the card expires
     * @param {String} expiraryMonth month the card expires
     * @param {String} cardNickname what the user would like to recognise this card as
     * @param {String} token card token
     * @param {String} brand brand of card eg. visa
     * @param {String} cardHolder the card holders name as it appears on the card
     * @returns {Promise} Promise
     */
    addMethod(lastFourDigits, provider, isDefaultCard, expiraryYear, expiryMonth, cardNickname, token, brand, cardHolder) {
        return new Promise((resolve, reject) => {
            super.post('payments/methods', {
                "last_four_digits": lastFourDigits,
                "provider": provider,
                "default": isDefaultCard,
                "expiry_year": expiraryYear,
                "card_nickname": cardNickname,
                "token": token,
                "expiry_month": expiryMonth,
                "brand": brand,
                "card_holder": cardHolder
            }, function (data) {
                resolve(new PaymentMethods(data));
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * Updates basic details of payment method
     * @param {String} methodId payment method id
     * @param {Boolean} isDefaultCard should be used as default for billing
     * @param {String} cardNickname what the user would like to recognise this card as
     * @returns {Promise} Promise
     */
    updateMethod(methodId, isDefaultCard, cardNickname) {
        return new Promise((resolve, reject) => {
            super.post('payments/methods/'.concat(methodId), {
                "default": isDefaultCard,
                "card_nickname": cardNickname
            }, function (data) {
                resolve(new PaymentMethods(data));
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * Deletes payment method
     * @param {String} methodId payment method id to be deleted
     * @returns {Promise} Promise
     */
    deleteMethod(methodId) {
        return new Promise((resolve, reject) => {
            super.post('payments/methods/'.concat(methodId).concat('/delete'), {
            }, function (data) {
                resolve(new PaymentMethods(data));
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * Returns a payment request by id
     * @param {String} paymentRequestId the id of the payment request to be returned
     * @returns {Promise} Promise
     */
    request(paymentRequestId) {
        return new Promise((resolve, reject) => {
            super.get('payments/requests/'.concat(paymentRequestId), {
            }, function (data) {
                resolve(new PaymentRequest(data.payment_request));
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * Pays a payment request with a specified method
     * @param {String} paymentMethodId payment method id
     * @param {String} paymentRequestId payment request id
     * @returns {Promise} Promise
     */
    pay(paymentMethodId, paymentRequestId) {
        return new Promise((resolve, reject) => {
            super.post('payments/pay', {
                "payment_method_id": paymentMethodId,
                "payment_request_id": paymentRequestId
            }, function (data) {
                resolve(new PaymentRequest(data.payment_request));
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * Gets a list of past payments
     * @returns {Promise} Promise resolve = new Payments()
     */
    history() {
        return new Promise((resolve, reject) => {
            super.get('payments/history', {
            }, function (data) {
                resolve(new Payments(data));
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
     * Gets the invoice details of a specific payment
     * @param {String} paymentId a payment id
     * @returns {Promise} Promise
     */
    invoice(paymentId) {
        return new Promise((resolve, reject) => {
            super.post('payments/invoice', {
                "payment_id": paymentId
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }
}