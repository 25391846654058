
import SubscriptionOption from './SubscriptionOption';

export default class SubscriptionOptions {

    constructor(json) {
        console.log('Suboptions '.concat(json));
        this.options = json.subscription_options ? mapSubscriptions(json.subscription_options) : null;
    }

    toString() {
        return "Subscription Options: ".concat(this.options);
    }
}

const mapSubscriptions = (array) => {
    var optionsArray = [];
    array.forEach(function (item) {
        var optionItem = new SubscriptionOption(item);
        optionsArray.push(optionItem);
    });
    return optionsArray;
}