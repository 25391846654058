import BaseModel from "./BaseModel";
import ConnectionType from '../Connect/ConnectionType';

class MediaModel extends BaseModel{

    static getInstance(){
      if(MediaModel.instance === undefined){
        MediaModel.instance = new MediaModel();
      }
      return MediaModel.instance;
    }

    constructor(){
      super();
      this.data.files={};
      this.addMediaToUploadSet = this.addMediaToUploadSet.bind(this);
    }

    addMediaToUploadSet(files){
      this.data.files={...this.data.files, ...files}
      this.dispatch(ConnectionType.TYPE_MEDIA_FOR_UPLOAD_UPDATED,this.data)
    }

    addMediaToNewTaskUpload(files){
      this.data.files={...this.data.files, ...files}
      this.dispatch(ConnectionType.TYPE_MEDIA_FOR_TASK_UPDATED,this.data)
    }

    clear(){
      var fileKeys = Object.keys(this.data.files);

      for (var key of fileKeys){
        delete this.data.files[key];
      }

      this.dispatch(ConnectionType.TYPE_MEDIA_FOR_UPLOAD_UPDATED,this.data)
      this.dispatch(ConnectionType.TYPE_MEDIA_FOR_TASK_UPDATED,this.data)
    }

}

export default MediaModel;