import React from 'react';
import HeyJudeUtils from '../../../../Utils/HeyJudeUtils';
import MediaControl from '../../../../Control/MediaControl'
import MessageControl from '../../../../Control/MessageControl';
import Utils from '../../../../Utils/Utils';

const icon = require('../../../../Assets/Img/messageIconPDF.png');

class MessageTypeDocument extends React.PureComponent{

  constructor(props){
    super(props)

    this.state = {
      blob : undefined,
      resendOnSend:false,
      resendOnUpload:false,
      loading:(this.props.data.pending)
                ? false 
                : true,
      src: (this.props.data.pending)
             ? this.props.data.pendingURL
             : undefined
    }

    this.renderResend = this.renderResend.bind(this);
    this.renderQuickViewButton = this.renderQuickViewButton.bind(this);

    this.uploadAttachment = this.uploadAttachment.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.resendMessage = this.resendMessage.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
  }

  componentDidMount(){
    if(this.props.data.pending){
      this.uploadAttachment();
    }
  }

  async uploadAttachment(){
    var uploadResponse = await MessageControl.getInstance().uploadAttachment(this.props.data.pendingFile)
    this.setState({resendOnUpload:!uploadResponse.result})

    if(!uploadResponse.result){
      return
    }
    this.attachmentId = uploadResponse.data;
    this.sendMessage();
  }

  async sendMessage(){
    var messageResponse = await MessageControl.getInstance().sendMessage({
      type:'document',
      taskId:this.props.data.taskId,
      attachmentId:this.attachmentId
    })

    this.setState({resendOnSend:!messageResponse})
  }

  resendMessage(){
    this.setState({
      resendOnSend:false,
      resendOnUpload:false,
    })
    this.sendMessage();
  }

  async downloadAttachment(){
    var response = await HeyJudeUtils.JudeManager().Attachments.download(this.props.data.attachmentId);
    window.open(URL.createObjectURL(response),"_blank")
  }

  renderResend(){
    if(!this.state.resendOnSend && !this.state.resendOnUpload){
      return null;
    }
    
    return(
      <div>
        <button onClick={this.resendMessage}>
          Error, press to resend
        </button>
      </div>
    )
  }

  renderQuickViewButton(){
    return(
      <button onClick={this.downloadAttachment}>View document</button>
    )
  }

  render(){
    return(
      [
        <img className="MediaThumbnail" src={icon} alt="placeHolder" onClick={this.downloadAttachment}/>,
        this.renderQuickViewButton(),
        this.renderResend()
      ]
    )
  }
}

export default MessageTypeDocument;


