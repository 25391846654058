import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './MModal.scss';

class MModal extends React.PureComponent {

  render() {
    // Render nothing if the "show" prop is false
    if (!this.props.show) {
      return null;
    }

    return ReactDOM.createPortal(
      <aside
        tag="aside"
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
        className="modalBackground"
        onClick={this.props.onOutsideClick}
        onKeyDown={this.props.onKeyDown}
      >
        <div className="modalContainer">
          <button 
            onClick={this.props.onClose}
            className="closeModal"
            aria-label="Close Modal"
            aria-labelledby="close-modal"
          >
            X
          </button>

          <div className="modalContent">
            {this.props.children}
          </div>
        </div>
      </aside>,
      document.getElementById('root')
    );
  }
}

MModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default MModal;