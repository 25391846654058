
export default class AttachmentDetail {
    // This is an dictionary object, in the format iso:phonenumber, eg. AU:"+61290370028"
    constructor(json) {
        this.extension = json.extension;
        this.id = json.id;
        this.mime = json.mime;
        this.name = json.name;
        this.size = json.size;
    }

    toString() {
        return "Attachment Detail Object: "
            .concat(' id: ').concat(this.id)
            .concat(' extension: ').concat(this.extension)
            .concat(' name: ').concat(this.name)
            .concat(' size: ').concat(this.size)
            .concat(' mime: ').concat(this.mime)
    }
}