import React from 'react'

const iconStarFull = require('../../../../../Assets/Img/iconStarFull.png')
const iconStarOutline = require('../../../../../Assets/Img/iconStarOutline.png')

class RatingStar extends React.PureComponent {

  constructor(props){
    super(props)

    this.renderRating = this.renderRating.bind(this)
    this.onRatingPressed = this.onRatingPressed.bind(this)
  }

  renderRating(){
    if(this.props.value <= this.props.rating) {
      return iconStarFull
    } else {
      return iconStarOutline
    }
  }

  onRatingPressed(){
    if(this.props.onRatingUpdated !== undefined) {
      this.props.onRatingUpdated(this.props.value)
    }
  }

  render(){
    return(
      <div className="RatingStar" onClick={this.onRatingPressed}>
        <img src={this.renderRating()} />
      </div>
    )
  }
}

export default RatingStar