
import { ApiHelper } from '../Helpers/ApiHelper';
import { Contacts } from '../Models/';

export default class Subscriptions extends ApiHelper {

    constructor(environment, program, xApiKey, callbackTokenUpdated) {
        super(environment, program, xApiKey, callbackTokenUpdated);
    }
    
    /**
    * Returns a google map png image
    * @returns {Promise} promise
    */
    getMap() {
        return new Promise((resolve, reject) => {
            super.get('map', {
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }

    // Currently not returning badge count
    /**
    * Returns the badge count displayed on app icon
    * @returns {Promise} promise
    */
    getBadgeCount() {
        return new Promise((resolve, reject) => {
            super.get('badge', {
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
    * Returns featured image giff.
    * @returns {Promise} promise
    */
    getFeaturedImage() {
        return new Promise((resolve, reject) => {
            super.get('featured-image', {
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
    * Returns the amount of hours that jude has saved the user.
    * @returns {Promise} promise
    */
    getHoursSaved() {
        return new Promise((resolve, reject) => {
            super.get('hours-saved', {
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
    * Returns social messages
    * @returns {Promise} promise
    */
    getSocialMessages() {
        return new Promise((resolve, reject) => {
            super.get('social-messages', {
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
    * Returns all the possible contact numbers avaliable to contact Hey Jude.
    * @returns {Promise} promise
    */
    getContacts() {
        return new Promise((resolve, reject) => {
            super.get('contacts', {
            }, function (data) {
                resolve(new Contacts(data.contacts));
            }, function (data) {
                reject(data);
            });
        });
    }

    /**
    *  {String} phone number(msisdn)
    * @returns {Promise} promise
    */
    getCountries() {
        return new Promise((resolve, reject) => {
            super.get('countries', {
            }, function (data) {
                resolve(data);
            }, function (data) {
                reject(data);
            });
        });
    }
}