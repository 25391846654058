
import { ApiHelper } from '../Helpers/ApiHelper';
import { SubscriptionOptions, SubscriptionStatus } from '../Models/';

export default class Subscriptions extends ApiHelper {

    constructor(environment, program, xApiKey, callbackTokenUpdated) {
        super(environment, program, xApiKey, callbackTokenUpdated);
    }

    getOptions() {
        return new Promise((resolve, reject) => {
            super.get('subscriptions/options', {
            }, function (data) {
                resolve(new SubscriptionOptions(data));
            }, function (data) {
                reject(data);
            });
        });
    }

    getStatus() {
        return new Promise((resolve, reject) => {
            super.get('subscriptions/status', {
            }, function (data) {
                resolve(new SubscriptionStatus(data));
            }, function (data) {
                reject(data);
            });
        });
    }

    selectOption(subscriptionOptionId) {
        return new Promise((resolve, reject) => {
            super.post('subscriptions/select', {
                "subscription_option_id": subscriptionOptionId
            }, function (data) {
                resolve(new SubscriptionStatus(data));
            }, function (data) {
                reject(data);
            });
        });
    }

    setAutoRenew(boolValue) {
        return new Promise((resolve, reject) => {
            super.post('subscriptions/auto-renew', {
                "auto_renew": boolValue
            }, function (data) {
                resolve(new SubscriptionStatus(data));
            }, function (data) {
                reject(data);
            });
        });
    }
}

