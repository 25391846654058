import React from 'react';
import './MMenu.scss';
import MModalButton from '../MModalButton';

class MMenu extends React.PureComponent{

	constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="MMenu_Hey">
        <MModalButton title="I want to go to ...">
          <br/><br/>
          <p className="Hey_Copy">We’ve got the A-Z of activities. Ask us about activities to do in your area.</p>
        </MModalButton>
        <MModalButton title="Please make a reservation at...">
          <br/><br/>
          <p className="Hey_Copy">Ask what’s open in your area and we’ll book a table.</p>
        </MModalButton>
        <MModalButton title="I’m having car troubles...">
          <br/><br/>
          <p className="Hey_Copy">Car trouble? We’ll source spares or help you get to a mechanic to get you back on the road.</p>
        </MModalButton>
        <MModalButton title="I need assistance with renewing my...">
          <br/><br/>
          <p className="Hey_Copy">If you licence disc has expired, you can count on us to help you get it renewed.</p>
          <p className="Hey_Copy">Let us pick the spa and make the booking for you, and we'll be able to give you some great specials and extra treatments.</p>
        </MModalButton>
        <MModalButton title="I'm planning a holiday...">
          <br/><br/>
          <p className="Hey_Copy">What can we do to assist with your next vacay? Advice, itineraries, bookings… whatever it is, we’re here for you.</p>
        </MModalButton>
      </div>
    );
  }
}

export default MMenu;
