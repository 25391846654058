import React from 'react';

import MediaModel from '../../../Model/MediaModel';
import ConnectionType from '../../../Connect/ConnectionType';
import MediaControl from '../../../Control/MediaControl';
import Button from '../../Button';

import theme from '../../../Theme/theme.module.scss';


const documentIcon = require('../../../Assets/Img/iconDocument.png')
const recordIcon = require('../../../Assets/Img/iconRecord.png')
const iconClose = require('../../../Assets/Img/iconClose.png')

class NewTaskMediaList extends React.PureComponent{

  static defaultProps = {
    theme: 'default'
  };

  constructor(props){
    super(props);

    this.state={
      data: MediaModel.getInstance().getData().files
    }

    this.onClearAttachmentsPressed = this.onClearAttachmentsPressed.bind(this);
    this.onAttachmentListUpdated = this.onAttachmentListUpdated.bind(this);
  }

  componentDidMount(){
    MediaModel.getInstance().addConnection(ConnectionType.TYPE_MEDIA_FOR_TASK_UPDATED,this.onAttachmentListUpdated)
  }

  componentWillUnmount(){
    MediaModel.getInstance().removeConnection(ConnectionType.TYPE_MEDIA_FOR_TASK_UPDATED,this.onAttachmentListUpdated)
  }
  onClearAttachmentsPressed(){
    MediaControl.getInstance().clear();
  }

  onAttachmentListUpdated(data){
    this.setState({
      data:data.files,
    })

    this.forceUpdate();
  }

  renderLayout(){
    var displayList = [];
    
    let data = this.state.data
    let keys = Object.keys(data)

    for (var key of keys){
      let fileData = data[key]

      if(fileData.type.includes("pdf")){
        displayList.push(
          <div key={fileData.id} className={`${theme.default} NewTaskMediaAttachmentListItem`}>
            <img src={documentIcon} alt="Attachment"/>
          </div>
        )
      }else if(fileData.type.includes("image")){
        displayList.push(
          <div key={fileData.id} className={`${theme.default} NewTaskMediaAttachmentListItem`}>
            <img src={fileData.url} alt="Attachment"/>
          </div>
        )
      }else if(fileData.type.includes("audio")){
        displayList.push(
          <div key={fileData.id} className={`${theme.default} NewTaskMediaAttachmentListItem`}>
            <img src={recordIcon} alt="Attachment"/>
          </div>
        )
      }
    }

    return displayList;
  }

  render(){
    if(Object.keys(this.state.data).length === 0){
      return null;
    }

    return(
      <div className={`${theme[this.props.theme]} NewTaskMediaAttachmentList`}>
        <div className="NewTaskMediaAttachmentContainer">
          {this.renderLayout()}
        </div>
        <Button
          onClick={this.onClearAttachmentsPressed}
          theme={this.props.theme}
          textonly="true"
          className="fz-15 p-5 icon-cancel"
        ></Button>
      </div>
    )
  }

}

export default NewTaskMediaList;