

export default class Message {

    constructor(json) {
        // Basic 
        this.fromUser = json.from_user ? json.from_user : false;
        this.fromUserString = json.from_user_string ? json.from_user_string : null;
        this.id = json.id ? json.id : null;
        this.options = json.options ? json.options : null;
        this.text = json.text ? json.text : null;
        this.type = json.type ? json.type : null;
        this.unixTimeStamp = json.unix_timestamp ? json.unix_timestamp : null;
        this.paymentRequestId = json.payment_request_id ? json.payment_request_id : null;

        // When type is payment
        this.amount = json.amount ? json.amount : null;
        this.currency = json.currency ? json.currency : null;
        this.description = json.description ? json.description : null;
        this.supplier = json.supplier ? json.supplier : null;
        this.taskId = json.task_id ? json.task_id : null;
        this.status = json.status ? json.status : null;

        // When type is map
        this.imageUrl = json.image_url ? json.image_url : null;
        this.latitude = json.latitude ? json.latitude : null;
        this.longitude = json.longitude ? json.longitude : null;
        this.address = json.address ? json.address : null;

        // When type is image or document
        this.attachmentId = json.attachment_id ? json.attachment_id : null;
        this.extension = json.extension ? json.extension : null;
        this.mime = json.mime ? json.mime : null;
        this.size= json.size ? json.size : null;

        // When type is vendors
        this.vendors = json.vendors ? json.vendors : null;

        
    }

    toString() {
        return "Message Object: "
            .concat(' fromUser: ').concat(this.fromUser)
            .concat(' fromUserString: ').concat(this.fromUserString)
            .concat(' id: ').concat(this.id)
            .concat(' options: ').concat(this.options)
            .concat(' text: ').concat(this.text)
            .concat(' type: ').concat(this.type)
            .concat(' unixTimeStamp: ').concat(this.unixTimeStamp)
            .concat(' amount: ').concat(this.amount)
            .concat(' currency: ').concat(this.currency)
            .concat(' description: ').concat(this.description)
            .concat(' supplier: ').concat(this.supplier)
            .concat(' taskId: ').concat(this.taskId)
            .concat(' imageUrl: ').concat(this.imageUrl)
            .concat(' latitude: ').concat(this.latitude)
            .concat(' longitude: ').concat(this.longitude)
            .concat(' address: ').concat(this.address)
            .concat(' attachmentId: ').concat(this.attachmentId)
            .concat('extension: ').concat(this.extension)
            .concat(' mime: ').concat(this.mime)
            .concat(' size: ').concat(this.size)     
            .concat(' paymentRequestId: ').concat(this.paymentRequestId)
    }
}

