
export default class SubscriptionOption {

    constructor(json) {
        this.id = json.id ? json.id : null;
        this.name = json.name ? json.name : null;
        this.description = json.description ? json.description : null;
        this.currency = json.currency ? json.currency : null;
        this.price = json.price ? json.price : null;
        this.available = json.available ? json.available : null;
        this.type = json.type ? json.type : null;
        this.isPro = json.is_pro ? json.is_pro : null;
        this.proHours = json.pro_hours ? json.pro_hours : null;
        this.duration = json.duration ? json.duration : null;
    }

    toString() {
        return "SubscriptionOption Object: "
            .concat(' id: ').concat(this.id)
            .concat(' name: ').concat(this.name)
            .concat(' description: ').concat(this.description)
            .concat(' currency: ').concat(this.currency)
            .concat(' price: ').concat(this.price)
            .concat(' type: ').concat(this.type)
            .concat(' isPro: ').concat(this.isPro)
            .concat(' proHours: ').concat(this.proHours)
            .concat(' duration: ').concat(this.duration)
    }
}
